import React from "react";
import styled from "@emotion/styled";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import DeviceVerify from "./DeviceVerify";
import loadable from "@loadable/component";
const CardCashSpinner = loadable(() =>
  import(/* webpackChunkName: "CardCashSpinner" */ "./CardCashSpinner")
);

const Signup = styled.div`
  position: relative;
  background-color: rgba(13, 2, 54, 1);
  border-radius: 0px 0px 400px 0px;
  overflow: hidden;

  @media (max-width: 1600px) {
    border-radius: 0px 0px 350px 0px;
  }
  @media (max-width: 1350px) {
    border-radius: 0px 0px 300px 0px;
  }
  @media (max-width: 1065px) {
    border-radius: 0px 0px 250px 0px;
  }
  @media (max-width: 950px) {
    border-radius: 0px 0px 200px 0px;
  }
  @media (max-width: 830px) {
    border-radius: 0px;
  }
`;

const MainSignUp = styled.div`
  padding-top: 70px;
  padding-bottom: 100px;
`;

const AbsoluteCover = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.4;
  background: url(https://cdn.cardcash.com/website/ui/signin/shoppingcart.jpeg)
    no-repeat;
  padding-top: 70px;
  background-position-x: -249px;
  background-position-y: 18%;
  background-size: 113%;
  mix-blend-mode: luminosity;

  @media (max-width: 1950px) {
    background-size: 116%;
  }
  @media (max-width: 1600px) {
    background-size: 120%;
  }
  @media (max-width: 1350px) {
    background-size: 133%;
  }
  @media (max-width: 1200px) {
    background-size: 150%;
  }
  @media (max-width: 1065px) {
    background-position-x: -347px;
    background-size: 169%;
  }
  @media (max-width: 950px) {
    background-size: 191%;
  }
  @media (max-width: 830px) {
    background-size: 225%;
  }
  @media (max-width: 700px) {
    background-size: cover;
  }
`;

const MainContent = styled.div`
  width: 579px;
  margin: 0 auto;
  position: relative;

  @media (max-width: 580px) {
    width: 100%;
  }
`;

const Loader = styled.div`
  background-color: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SignInUpPageWrapper = ({
  title,
  metaProperty,
  metaContent,
  children,
  signinLoader,
  showDeviceVerifyPopUpModal
}) => (
  <Signup className="mar-t-125">
    <AbsoluteCover />
    <MainSignUp>
      <Helmet
        title={title}
        meta={[
          {
            property: metaProperty,
            content: metaContent
          }
        ]}
      />
      <MainContent>
        {showDeviceVerifyPopUpModal ? (
          <DeviceVerify />
        ) : (
          <>
            {signinLoader && (
              <Loader>
                <CardCashSpinner />
              </Loader>
            )}

            {children}
          </>
        )}
      </MainContent>
    </MainSignUp>
  </Signup>
);

export default connect(state => ({
  signinLoader: state.userActions.loaderForSignin,
  showDeviceVerifyPopUpModal: state.userActions.showDeviceVerifyPopUpModal
}))(SignInUpPageWrapper);
