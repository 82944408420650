import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { ClearedStyleRouterLink } from "@modules/Components";
import SignInUpSocialSignIns from "./SignInUpSocialSignIns";
import AdditionalGoogleForm from "./forms/AdditionalGoogleForm";

const FormContainer = styled.div`
  background-color: #f8f7f8;
  padding-top: ${props => props.paddingTop};

  @media (max-width: 330px) {
    padding-top: 32px;
  }
`;

const InnerWidthPadding = styled.div`
  max-width: 80%;
  margin: 0 auto;

  @media (max-width: 580px) {
    max-width: 100%;
    width: 90%;
  }
`;

const Bottom = styled.div`
  box-shadow: 0 5px 20px 0 rgba(38, 38, 38, 0.05);
  background-color: #ffffff;
`;

const CommentContainer = styled.div`
  text-align: center;
  padding-top: 38px;
  padding-bottom: 38px;
`;

const Comment = styled.div`
  font-size: 18px;
  font-style: italic;
  line-height: 1.78;
  color: #1f2021;

  @media (max-width: 520px) {
    font-size: 16px;
  }
`;

const CommentName = styled.div`
  font-size: 14px;
  color: #777984;
  margin-top: 8px;

  @media (max-width: 520px) {
    font-size: 13px;
  }
`;

const CommentDots = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Dot = styled.span`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: ${props => props.bgColor};
  margin-right: ${props => props.marginRight};
  margin-left: ${props => props.marginLeft};
`;

const Header = styled.h1`
  font-size: 32px;
  font-weight: 500;
  text-align: center;
  color: #1f2021;

  @media (max-width: 330px) {
    font-size: 29px;
  }
  @media (max-width: 290px) {
    font-size: 27px;
  }
`;

const Divider = styled.div`
  height: 1px;
  background-color: rgba(119, 121, 132, 0.15);
`;

const SignInDiv = styled.div`
  font-size: 16px;
  text-align: center;
  color: #777984;
  padding-top: 27px;
  padding-bottom: 27px;

  @media (max-width: 300px) {
    font-size: 15px;
  }
`;

const SignInLink = styled.span`
  color: #12b2ea;
  font-weight: bold;
  cursor: pointer;
`;

const Link = styled(ClearedStyleRouterLink)`
  color: #12b2ea;
`;

const CreateAccount = styled.div`
  font-size: 16px;
  text-align: center;
  color: #777984;
`;

const CreateSpan = styled.span`
  color: #12b2ea;

  @media (max-width: 445px) {
    display: block;
  }
`;

const RouterLink = styled(ClearedStyleRouterLink)`
  color: #12b2ea;
  font-weight: bold;
  cursor: pointer;
`;

const ReferrelDiv = styled.div`
  background-color: #ebebed;
  width: 93%;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
  color: #777984;
  margin: 0 auto;
  padding: 16px 45px;
  margin-bottom: 20px;

  @media (max-width: 400px) {
    padding: 12px 15px;
    font-size: 15px;
  }
`;

const ReferrelBold = styled.span`
  font-weight: 500;
  color: #1f2021;
`;

const customerComments = [
  {
    id: 1,
    name: "Stacy D.",
    comment:
      "CardCash always has the biggest savings on the cards I am looking for."
  },
  {
    id: 2,
    name: "Lisa M.",
    comment: "SUPER easy way to save money on things I’m already going to buy."
  },
  {
    id: 3,
    name: "Nina B.",
    comment:
      "CardCash is a quick and easy way to save money on something I’m already buying."
  },
  {
    id: 1,
    name: "Huquin J.",
    comment:
      "I’ve used CardCash for years, and I know they’re reliable and get the gift card to me fast."
  },
  {
    id: 2,
    name: "Reano M.",
    comment:
      "CardCash allows me to see real savings. Thank you CardCash you guys are Awesome!"
  },
  {
    id: 3,
    name: "Matt K.",
    comment:
      "I LOVE that CardCash gives you the cards immediately upon purchase if they are egift cards."
  }
];

const SignInUpPageFormContainer = ({
  header,
  children,
  location,
  page,
  submissionHistoryObject,
  referrerFirstName,
  showReferrel
}) => {
  const [currentComment, changeComment] = useState(0);
  const [currentArraySlicer, changeCurrentArraySlicer] = useState("firstHalf");
  const [currentArray, changeCurrentArray] = useState(
    customerComments.slice(0, 3)
  );
  const [googleSignInAdditionalInfo, setGoogleSignInAdditionalInfo] =
    useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      let counter;
      changeComment(prevCounter => {
        counter = prevCounter < 2 ? prevCounter + 1 : 0;
        return counter;
      });
      if (counter === 2) {
        let localSlicer;
        changeCurrentArraySlicer(currSlicer => {
          localSlicer = currSlicer === "firstHalf" ? "secondHalf" : "firstHalf";
          return localSlicer;
        });
        changeCurrentArray(() =>
          localSlicer === "firstHalf"
            ? customerComments.slice(3, 6)
            : customerComments.slice(0, 3)
        );
      }
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <FormContainer paddingTop={showReferrel ? "16px" : "50px"}>
        {showReferrel && (
          <ReferrelDiv>
            Thanks to {referrerFirstName}, when you sign up, we will email you a
            <ReferrelBold>
              &nbsp;$5 coupon off your first purchase!
            </ReferrelBold>
          </ReferrelDiv>
        )}
        <InnerWidthPadding>
          <Header>
            {googleSignInAdditionalInfo?.missingData
              ? "We need some additional information"
              : header}
          </Header>
          {googleSignInAdditionalInfo?.missingData ? (
            <AdditionalGoogleForm
              googleSignInAdditionalInfo={googleSignInAdditionalInfo}
            />
          ) : (
            <>
              {children}

              <SignInUpSocialSignIns
                submissionHistoryObject={submissionHistoryObject}
                setGoogleSignInAdditionalInfo={setGoogleSignInAdditionalInfo}
              />
              <Divider />
            </>
          )}
          {googleSignInAdditionalInfo?.missingData ? (
            <SignInDiv>
              <SignInLink onClick={() => setGoogleSignInAdditionalInfo(false)}>
                Go back
              </SignInLink>
            </SignInDiv>
          ) : (
            <SignInDiv>
              {page === "signUp" ? (
                <>
                  <span>Already have an account?&nbsp;</span>
                  <SignInLink>
                    <Link to="/login/">Login</Link>
                  </SignInLink>
                </>
              ) : (
                <CreateAccount>
                  New to CardCash?&nbsp;
                  <CreateSpan>
                    <RouterLink
                      to={{
                        pathname: "/sign-up/",
                        state: {
                          from: location.state ? location.state.from : "/"
                        }
                      }}
                    >
                      Create an account
                    </RouterLink>
                  </CreateSpan>
                </CreateAccount>
              )}
            </SignInDiv>
          )}
        </InnerWidthPadding>
      </FormContainer>
      <Bottom>
        <InnerWidthPadding>
          <CommentContainer>
            <Comment>"{currentArray[currentComment].comment}"</Comment>
            <CommentName>{currentArray[currentComment].name}</CommentName>
            <CommentDots>
              {currentArray.map((item, index) => (
                <Dot
                  marginRight={item.id === 2 ? "10px" : ""}
                  marginLeft={item.id === 2 ? "10px" : ""}
                  bgColor={
                    item.id - 1 === currentComment
                      ? "#ff9c1b"
                      : "rgba(119, 121, 132, 0.16)"
                  }
                  key={index}
                />
              ))}
            </CommentDots>
          </CommentContainer>
        </InnerWidthPadding>
      </Bottom>
    </>
  );
};
export default withRouter(
  connect(state => ({
    customerInfo: state.customerInfo,
    config: state.config,
    signinLoader: state.userActions.loaderForSignin,
    referrerFirstName: state.refer.referrerFirstName
  }))(SignInUpPageFormContainer)
);
